import { Route, Routes } from "react-router-dom"
import "./App.scss"
import { useJsApiLoader } from '@react-google-maps/api';
import DefaultLayout from "./Pages/Layout/DefaultLayout"
import Unauthorized from "./Pages/Layout/Unauthorized"
import PersistLogin from "./Hooks/PersistLogin"
import RequireAuth from "./Hooks/RequireAuth"
import TabLayout from "./Pages/Layout/TabLayout/TabLayout"
import TabDashboard from "./Pages/Dashboard/Dashboard"
import NotFound from "./Pages/NotFound/NotFound"
import Login from "./Pages/Login/Login"
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Profile from "./Pages/Profile/Profile"
import Register from "./Pages/Register/Register"
import Pets from "./Pages/Pets/Pets"
import Bookings from "./Pages/Bookings/Bookings"
import Payments from "./Pages/Payments/Payments"
import Gallery from "./Pages/Bookings/Gallery/Gallery";


function App() {
    const navigate = useNavigate()
    const location = useLocation() as any

    const [loggedInUser, setLoggedInUser] = useState<any>(null)
    const [resMade, setResMade] = useState<any>(false)

    const handleLoginSuccess = (userData) => {
        setLoggedInUser(userData)
        const from = location.state?.from?.pathname || `${"Dashboard"}`
        navigate(from, { replace: true })
    }

    const GOOGLE_MAP_KEY: any = process.env.REACT_APP_GMAP_API_KEY
const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: ['places']
});

    return (
        <Routes>
            <Route path="/" element={<DefaultLayout />}>
                <Route path="login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
                <Route path="register" element={<Register />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth allowedRoles={[123]} />}>
                        <Route path="tablet" element={<TabLayout loginData={loggedInUser} />}>
                            <Route path="/tablet" element={<TabDashboard loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout loginData={loggedInUser} />}>
                            <Route path="/" element={<TabDashboard loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout loginData={loggedInUser} />}>
                            <Route path="/profile" element={<Profile loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout loginData={loggedInUser} />}>
                            <Route path="/pets" element={<Pets loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout loginData={loggedInUser} isReservationMade={(e)=>{
                            if(e === true){
                                setResMade(true)
                            }
                        }} />}>
                            <Route path="/bookings" element={<Bookings loginData={loggedInUser} isRefresh={resMade} setRefreshSuccess={(e)=>{if(e === true){setResMade(false)}}}/>} />
                        </Route>
                        <Route path="/" element={<TabLayout loginData={loggedInUser} />}>
                            <Route path="/payments" element={<Payments loginData={loggedInUser} />} />
                        </Route>
                        <Route path="/" element={<TabLayout loginData={loggedInUser} />}>
                            <Route path="/gallery" element={<Gallery loginData={loggedInUser} />} />
                        </Route>
                    </Route>
                    <Route path="/">
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    )
}

export default App
